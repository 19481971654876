@import "brix/widgets/widgets";

div.portlet {
  .tabular-report-widget {
    height: 100%;
    overflow-y: scroll;

    .title {
      text-align: center;
      margin-top: 5px;
      margin-bottom: 11px;
      padding-left: $widget-title-padding;
      padding-right: $widget-title-padding;
    }

    .table-findable {
      .title {
        margin-left: 160px;
        margin-right: 160px;
        padding-left: 0;
        padding-right: 0;
      }
    }

    tr:not(.search-headers) {
      th {
        position: sticky;
        top: 0;
        background-color: var(--#{$prefix}table-bg);
      }
    }

    th.measure,
    td.measure {
      text-align: right;
    }

    td.measure {
      @include tabular-numerals;
    }

    .table-bordered {
      border-left-width: 0;
      border-right-width: 0;

      .search-headers th {
        padding-right: 5px;
      }
    }

    table.dataTable {
      thead > tr > th[class*="sorting"] {
        &:before,
        &:after {
          right: 5px;
        }
      }
    }

    .dt-container {
      position: relative;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    .dt-search {
      position: absolute;
      top: 12px;
      left: 10px;

      input {
        width: 140px;
        margin-left: 0;
      }
    }

    .dt-paging {
      margin: 10px 5px;;
    }

    .dt-length {
      margin: 10px 5px;
      display: flex;
      align-items: center;

      label {
        margin: 0;
      }

      select {
        min-width: 60px;
      }
    }
  }
}
