.dashboard-template {
  &__line-of-business-icon {
    max-width: 100%;
    max-height: 90px;
  }

  &__icon-row {
    display: flex;
    align-items: flex-start;

    span {
      vertical-align: middle;
      color: var(--#{$prefix}secondary);
    }
  }
}
