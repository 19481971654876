$table-cell-padding-y-sm: 0.25rem;

@mixin tabular-numerals {
  font-family: -apple-system-monospaced-numbers, -apple-system, -webkit-system-font, Helvetica, Arial, sans-serif;
  font-variant-numeric: tabular-nums;
}

.tabular-numerals {
  @include tabular-numerals;
}

.btn {
  min-width: 0;
}

.btn-secondary {
  @include button-outline-variant($primary);

  background: transparent;
}

.badge {
  padding: .3rem .6rem;
  vertical-align: middle;

  &-secondary {
    background-color: $gray-600;
    color: $white;
  }

  &-warning, &-info {
    color: $white
  }
}

.flash.alert {
  margin-bottom: 55px;
}

.wrap-content {
  white-space: normal !important;
}

.page-header {
  position: relative;
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eeeeee;

  .breadcrumbs {
    position: absolute;
    top: -38px;
    width: 100%;
  }

  .page-description {
    line-height: 1.2;
    @extend .text-muted;
  }

  h1 {
    strong {
      font-weight: $font-weight-normal;
    }
  }

  .actions,
  .header-details {
    float: right;
    margin-top: 4px;
    margin-left: 25px;

    position: absolute;
    bottom: 0px;
    right: 0px;

    li:last-child {
      padding-right: 0;

      a.btn-link {
        padding-right: 0;
      }
    }
  }

  .header-details {
    font-size: $font-size-md;
  }
}

.page-header--responsive {
  @extend .d-flex;
  @extend .flex-column;
  @extend .flex-sm-row;
  @extend .justify-content-between;
  @extend .align-items-sm-end;
  @extend .pb-3;
  margin-bottom: 1rem;
  border-bottom: 1px solid $gray-200;

  h1, .h1 {
    @extend .h3;
    max-width: 80vw;

    > small, > strong {
      @extend .me-2;
      @include media-breakpoint-down(md) {
        display: block;
      }
    }
  }
}

.page-header--no-border {
  margin-bottom: 0;
  border-bottom: none;
}

.page-header__actions {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-end;
  @extend .flex-wrap;
  height: 100%;

  @include media-breakpoint-down(sm) {
    margin-top: 0.5rem;
  }

  a:not(.btn) {
    @extend .btn;
    @extend .btn-link;
  }

  form.button_to > input, button:not(.btn) {
    @extend .btn;
    @extend .btn-primary;
  }

  form.button_to, button {
    @extend .ms-2;
  }
}

.page-subheader {
  @extend .page-header--responsive;
}

.page-subheader__actions {
  @extend .page-header__actions;
}

.confirmation-icon {
  color: $success;
  opacity: 0;
}

.warning-icon {
  color: $danger;
  opacity: 0;
}

.popover-container {
  .popover {
    max-width: none;
  }
}

.pagination-container {
  text-align: center;
}

span.affinity.label {
  width: 88px;
  margin-right: 3px;
}

.v-top { vertical-align: top !important; }

@media print {
  a[href]:after { content: none !important; }
  img { max-width: unset !important; }
}

.drag-cell {
  width: 40px;
  span { cursor: move; }
}

// iOS Safari will auto-zoom the entire page on an input when it gains focus
// and the font-size is less than 16px
input.no-auto-zoom {
  font-size: $font-size-md;
}

p.no-results {
  @extend .mt-5;
  @extend .mb-5;
  text-align: center;
}

.preserve-nl {
  white-space: pre-line !important;
}

pre {
  line-height: $line-height-base;
  padding: 10px;
  color: $gray-800;
  word-break: break-all;
  word-wrap: break-word;
  background-color: $body-tertiary-bg;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  max-height: 70vh;
  overflow-y: scroll;
}

.remove-paragraph-margins > p {
    margin: 0;
}

.drag-handle {
  cursor: grab;
}

.break-overflow-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

form.button_to {
  display: inline;

  [type="submit"].inline-link {
    background-color: transparent;
    border: none;
    color: $link-color;
    display: inline;
    font-weight: inherit;
    margin: 0;
    padding: 0;
    text-decoration: $link-decoration;

    &:hover {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }
  }
}

.cursor-pointer { cursor: pointer; }

.card {
  .card-header ~ .table {
    margin-bottom: 0;

    tr:last-child {
      td,
      th {
        border-bottom-width: 0;
      }
    }
  }
}

.pagination {
  margin: map-get($spacers, 4) 0;
  justify-content: center;
}

.dropdown {
  .dropdown-drawer__parent {
    &:active, &:hover {
      background-color: $dropdown-link-hover-bg;

      &,
      .glyphicon {
        color: $dropdown-link-hover-color;
      }
    }
  }
}

.nav-tabs.underlined .nav-link.active {
  border: 0px;
  border-bottom: 4px solid $border-color;
}

.progress {
  border: 1px solid;
}
