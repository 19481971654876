.mejs {
  // main audio container
  &__container {
    border-radius: 25px;
    background-color: $gray-200;
    outline: none !important;

    // removes blue/gray outline when interacting with mediaelement components
    div, h1, a, img, span, button {
      outline: none;
    }
  }
  // removes gradient background on main audio container
  &__controls:not([style*='display: none']) {
    background: transparent;
    top: 0;
    align-items: center;
    height: 100%;
  }
  // background for the main horizontal strip in the middle of the player
  &__time-total {
    background-color: $white;
  }
  // background for the portion of the main horizontal strip that represent the % of the song already played
  &__time-current {
    background-color: $primary;
  }
  // drag handle for scrubbing time-current back and forth
  &__time-handle-content {
    background: transparent;
    border: none;
  }
  // time indicators to the left and right ofthe main horizontal strip
  &__time {
    color: $black;
    font-weight: $font-weight-normal;
  }
  // tooltip that displays current time when scrubbing
  &__time-float {
    border: 1px solid $black;
    border-radius: $border-radius;
    color: $black;
  }
  // bottom arrow for time-float tooltip
  &__time-float-corner {
    border-color: $black transparent transparent;
  }

  &__overlay-button, &__overlay-loading-bg-img, &__button {
    display: flex;
    align-items: center;

    & > button {
      position: relative;
      color: $primary;
    }
  }
}
