.help-content {
  img {
    margin-bottom: 3em;
  }

  font-size: $font-size-md;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  dt {
    @extend .col-12;
    @extend .col-md-2;
    text-align: left;
    font-weight: $font-weight-bold;
    margin: 5px 0;

    @media screen and (min-width: 768px) {
      text-align: right;
    }
  }

  dd {
    @extend .col-12;
    @extend .col-md-10;
    margin: 5px 0;

    &:last-of-type {
      margin-bottom: 20px;
    }
  }

  ol.steps {
    margin-top: 1.5em;

    li {
      margin-bottom: 1.5em;
      img {
        margin-bottom: 1em;
      }
    }
  }
}
