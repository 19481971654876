.tab-pane#recommendation {
  margin-top: 27px;
}

.no-pad {
  padding: 0;
  margin: 0;
}

ul.nav.underlined {
  li {
    a:focus, a:hover {
      border: 0px;
      border-bottom: 4px solid var(--#{$prefix}border-color);
    }
  }
}

ul.nav-tabs.underlined {
  border-bottom: 0px;
  li {
    a {
      border: 0px;
    }
    a:hover {
      border: 0px;
      border-bottom: 4px solid var(--#{$prefix}border-color);
    }

  }
  li.active {
    a {
      border: 0px;
      border-bottom: 4px solid var(--#{$prefix}border-color);
    }
    a:hover {
      border: 0px;
      border-bottom: 4px solid var(--#{$prefix}border-color);
    }
  }
}

ul.recent_tabs {
  li {
    a{
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}

ul#results {
  @extend .mt-4;

  li hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  li hr.top-flush {
    margin-top: 0px;
  }
}

.media-fine-print {
  line-height: 14px;
}

.missing-book-image {
  height: 100px;
  background-color: var(--#{$prefix}light);
  line-height: 100px;
  text-align: center;
  span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
}

ul {
  li.book-margin {
    margin-bottom: 15px;
  }
}

.purchase-count {
  width: 50px;
  text-align: right;
}

.assign-link,
.judgments-link {
  padding-left: 18px;
}

span.experiment-name {
  font-size: $font-size-lg;
}

span.reprocess-glyph {
  color: var(--#{$prefix}danger);
}
