$icon-size-lg: $font-size-base * 2;
$icon-size-md: $font-size-base * 1.5;
$icon-size-sm: $font-size-base * 0.75;

$font-size-md: $font-size-base * 1.1;
$font-size-xs: $font-size-base * 0.75;

h1, h2, h3 {
  margin: 20px 0 10px;
}

h4, h5, h6 {
  margin: 10px 0;
}

h1, h2, h3, h4, h5, h6 {
  small {
    font-weight: $font-weight-normal;
    color: $body-tertiary-color;
  }
}

.popover-header {
  margin-top: 0;
}

.glyphicon.icon-size-sm {
  font-size: $icon-size-sm;
}

.glyphicon.icon-size-lg {
  font-size: $icon-size-lg;
}

.glyphicon.icon-size-md {
  font-size: $icon-size-md;
}
