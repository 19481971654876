$_navbar-height: 52px;

[data-generate="toc"] {
  margin-bottom: map-get($spacers, 5);

  &:empty { display: none; }

  .nav-link {
    color: $text-muted;
    padding-top: map-get($spacers, 2);
    padding-bottom: map-get($spacers, 2);

    &.active {
      color: $primary;
      font-weight: $font-weight-bold;
    }
  }

  @for $_i from 1 to 7 {
    .nav-h#{$_i} {
      padding-left: calc((#{$_i} - 1) * #{$spacer});
    }
  }

  @include media-breakpoint-up(lg) {
    border-left: 1px solid $border-color;

    nav.sticky-top {
      @supports (position: sticky) {
        top: calc(#{$_navbar-height} + #{$spacer});
        height: auto;
        max-height: calc(100vh - #{$_navbar-height} - #{map-get($spacers, 5)});
        overflow-y: auto;
      }
    }
  }
}
