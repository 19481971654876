$column-widths: (
  5: 5%,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 30%,
  40: 40%
);

td, th {

  @each $prop, $width in $column-widths {
    &.width-#{$prop} {
      width: $width;
    }
  }

  &.time-ago-in-words-column {
    @extend .text-end;
  }

  &.actions-column {
    text-overflow: initial;
    overflow: initial;
    white-space: initial;
  }

  &.numeric-column {
    text-align: right;
  }
}

.table-responsive {
  table-layout: auto;

  td, th {
    white-space: nowrap;
  }
}

.truncate-oversize-column {
  max-width: 200px;

  &--sm {
    max-width: 100px;
  }

  &--md {
    max-width: 300px;
  }

  &--lg {
    max-width: 500px;
  }

  &--xl {
    max-width: 800px;
  }
}

.table th {
  border-top: none;
}

.table-dl th {
  border-top: 1px solid $gray-300;
}

.table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-layout-fixed {
  table-layout: fixed;
}

caption {
  caption-side: top;
}

th.member-count,
td.member-count {
  text-align: right;
  width: 80px;
}

th.actions,
td.actions {
  text-align: right;
}

th.icon-center,
td.icon-center {
  text-align: center;
}

th.remaining-count,
td.remaining-count {
  text-align: right;
  width: 80px;
}

th.task-count,
td.task-count {
  text-align: right;
}
td.task-count {
  @include tabular-numerals;
}

th span.avg {
  font-weight: $font-weight-normal;
}

table#audit-runs {
  th.status,
  td.status {
    text-align: right;
  }
}

th.narrow,
td.narrow {
  width: 80px;
}

th.date,
td.date {
  text-align: center;
  width: 140px;
}

.table__topic-headers th {
  @extend .text-center;
  @extend .left-border;

  &:first-of-type {
    border: none;
  }
}

th.top-header {
  text-align: center;
}

th.left-border,
td.left-border {
  border-left: 2px solid #ddd;
}

th.left-border-thin,
td.left-border-thin {
  border-left: 1px solid #ddd;
}

th.right-border,
td.right-border {
  border-right: 2px solid #ddd;
}

th.affinity-value,
td.affinity-value {
  width: 40px;
}

td .progress {
  margin-bottom: 0px;
  margin-top: 5px;
}

th, td {
  &.cell-xs { width:  80px; }
  &.cell-sm { width: 150px; }
  &.cell-md { width: 300px; }
}

table.table-dl {
  th {
    font-weight: $font-weight-lighter;
    width: 30%;
    text-align: right;
  }

  th.table-dl-sub-heading {
    font-weight: $font-weight-bold;
    text-align: center;
    background-color: $card-cap-bg;
  }

  tr td {
    white-space: normal;
    overflow: visible;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

table .help-block{
  white-space: normal;
}

.card {
  .table {
    th, td {
      padding-left: $card-spacer-x;
      padding-right: $card-spacer-x;
    }
  }
}

.table-responsive--disable-xl {
  @include media-breakpoint-up(xl) {
    overflow-x: unset;
    table-layout: fixed;
    -webkit-overflow-scrolling: unset;
  }
}

.table-row-with-list {
  li {
    &:not(:first-of-type){
      padding-top: 0.3rem;
    }
    &:not(:last-of-type){
      border-bottom: 1px solid $table-border-color;
      padding-bottom: 0.3rem;
    }
  }
}
