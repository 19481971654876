.dashboard-template {

  &__title {
    font-size: $h1-font-size;
    font-weight: $font-weight-bold;
  }

  &__title-sub {
    line-height: 20px;
    color: $dashboard-template-font-dark-gray;
  }

}
