.priorities.index {
  *:not(input:focus)::selection {
    background: transparent;
  }

  tr {
    &:not(.loading) {
      td:has(.visible-on-loading) { padding: 0; }
      .visible-on-loading { display: none; }
    }

    &.loading .hidden-on-loading { display: none; }
  }
}
