.dashboard-template {
  &__table-wrapper {
    background-color: var(--#{$prefix}body-bg);
  }

  // Adjust borders on bootstrap .table element
  &__table-light-border {
    margin-bottom: 0px;
    border-bottom: 1px solid $table-border-color;

    thead th {
      border-bottom: 0px;
    }

    tr {
      margin: 5px 0;
    }

    // Add bigger spacing on bootstrap .table element
    & > thead > tr > th,
    & > thead > tr > td,
    & > tbody > tr > th,
    & > tfoot > tr > th {
      padding: 10px 15px 10px 10px;
    }

    & > tbody > tr > td,
    & > tfoot > tr > td {
      padding: 25px 10px;
    }
  }
}
