body.response_pairs.index {
  form#scrape-form {
    width: 320px;
  }

  #side-by-side-scrape-options {
    .dropdown-divider.with-divider-text {
      position: relative;
      overflow: visible;

      span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: map-get($spacers, 1);
        background: $dropdown-bg;
        white-space: nowrap;
      }
    }
  }
}
