.evaluations.show {
  .form-inline {
    @extend .mt-2;
    @extend .mb-2;

    .select2-container {
      @extend .mb-2;
      @extend .me-2;
    }
    .btn-primary {
      @extend .mb-2;
    }
  }

  h3 {
    .btn-primary, small {
      @extend .ms-1;
    }
  }

  .table-responsive {
    @include media-breakpoint-down(xl) {
      max-height: 50vh;
      margin-bottom: 2rem;
    }
  }
}

.evaluations.new,
.evaluations.edit {
  form {
    .nested-fields {
      a.remove_fields {
        color: $dark;
      }

      .nested-select {
        + .select2-container {
          max-width: 60%;
        }
      }

      .select2-container--bootstrap {
        display: inline-block;
      }
    }
  }
}

#evaluations {
  th.name,
  td.name {
    width: 35%;
  }

  th.type,
  td.type {
    width: 35%;
  }

  th.evaluation-active,
  td.evaluation-active {
    text-align: center;
  }

  th.guidelines,
  td.guidelines {
    text-align: right;
  }
}

#evaluation-experiments,
#evaluation-input-sets,
#evaluation-endpoints,
#evaluation-organizations {
  th.name,
  td.name {
    width: 80%;
  }

  th.storefront,
  td.storefront {
    width: 40%;
  }

  th.actions,
  td.actions {
    width: 20%;
  }
}

th.evaluation-user-task-count {
  width: 50%;
  text-align: right;
}

td.evaluation-user-task-count {
  text-align: right;
}

th.evaluation-user-average-seconds {
  width: 25%;
  text-align: right;
}

td.evaluation-user-average-seconds {
  text-align: right;
}

.podcast-snippet, .lyric-snippet {
  padding: 5px 10px;
  background-color: $info-bg-subtle;
  width: fit-content;

  &__text {
    font-style: italic;

    mark {
      background: none;
      padding: 0;
      font-weight: $font-weight-bold;
    }
  }
}

.output-header {
  margin-left: 2rem;
}

[data-evaluation-type^='side_by_side'] {
  #input-metadata {
    ul.search-links {
      margin-bottom: 0;

      li {
        display: inline-block;

        &:not(:last-child)::after {
          content: '•';
          display: inline-block;
          color: $text-muted;
          margin: 0 map-get($spacers, 1);
        }
      }
    }
  }
}
