.scrape_input_comparisons.reader_view {
  --background-color: var(--#{$prefix}tertiary-bg);
  --component-background-color: var(--#{$prefix}body-bg);

  background: var(--background-color);

  .card {
    padding: 1rem 1.5rem 1.75rem;
    border: none;

    &.header-card {
      padding: 1.5rem;
      height: 100%;
    }

    .list-group-item {
      padding: 1rem 0;
    }
  }

  .table {
    --#{$prefix}table-bg: var(--component-background-color);

    tr:last-child {
      td,
      th {
        border-bottom-width: 0;
      }
    }

    th {
      border-bottom: none;
      vertical-align: top;
      padding-top: 1.5rem;
      padding-bottom: 1.25rem;
    }

    td {
      white-space: normal;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }

    a {
      color: inherit;
    }

    .output {
      width: 37%;
    }

    .score {
      font-size: $h5-font-size;
    }

    .table-gutter {
      width: 50px;
      background-color: var(--background-color);
      border: none;
    }

    .wide-border-left {
      border-left-width: 1.5rem;
      border-right-style: solid;
      border-color: var(--component-background-color);
    }

    .wide-border-right {
      border-right-width: 1.5rem;
      border-right-style: solid;
      border-color: var(--component-background-color);
    }
  }

  .output-media__image-container--responsive {
    justify-content: flex-start !important;

    img {
      padding-left: 0.75rem;
    }
  }
}

.comparison-reader-view {
  &__label {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    color: $text-muted;
    font-size: $small-font-size;
  }

  &__icon {
    display: flex;
    align-items: center;

    img.gray {
      // Setup target color for SVG's as #8B8B8B
      filter: invert(60%) sepia(2%) saturate(2%) hue-rotate(336deg) brightness(91%) contrast(88%);
    }

    img.blue {
      // Setup target color for SVG's as #425BA7
      filter: invert(39%) sepia(8%) saturate(5609%) hue-rotate(191deg) brightness(83%) contrast(83%);
    }
  }
}
