.data_sources.edit,
.data_sources.update {
  a.remove_fields {
    position: absolute;
    top: 8px;
    right: -10px;
    color: $dark;
  }
}

.cadences.index {
  form {
    label {
      font-weight: $font-weight-normal;
    }

    #lookback {
      width: 5em;
    }
  }
}
