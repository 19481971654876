.omniauth_callbacks.appleconnect {
  #login-failure {
    height: 75vh;

    .title {
      font-size: map-get($display-font-sizes, 1);
      font-weight: $font-weight-bold;
      color: $gray-400;
    }

    .reason {
      font-size: map-get($display-font-sizes, 6);
      font-weight: $font-weight-bold;
    }

    .support {
      font-size: $h4-font-size;
    }
  }
}
