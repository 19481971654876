$app-screenshot-max-size: 250px;
$app-screenshot-max-size--enlarged: 500px;

.app__screenshot-list {
  @extend .my-3;
  @extend .py-4, .px-4;

  white-space: nowrap;
  overflow: scroll;
  background-color: $body-tertiary-bg;
  border: 1px solid $border-color;
}

.app__screenshot {
  margin-right: 30px;
  max-height: $app-screenshot-max-size;
  max-width: $app-screenshot-max-size;
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-out;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;
    transform: translatey(-6px);
  }

  &--enlarged-portrait {
    max-height: $app-screenshot-max-size--enlarged;
    max-width: $app-screenshot-max-size--enlarged;
    height: $app-screenshot-max-size--enlarged;
  }

  &--enlarged-landscape {
    max-height: $app-screenshot-max-size--enlarged;
    max-width: $app-screenshot-max-size--enlarged;
    width: $app-screenshot-max-size--enlarged;
  }
}
