.table-searchable {
  tr.search-headers {
    th {
      border-bottom: 0;
      width: 1px; // added to prevent search headers from resizing when search icon is added, removed

      input,
      select,
      .select2-container {
        font-weight: $font-weight-normal;
      }
    }

    .input-group-btn {
      @extend .d-flex;
    }

    .input-group {
      flex-wrap: nowrap;

      .search-field { flex: 1; }

      .operator {
        flex-grow: 0;
        width: auto;
      }

      * + .search-field {
        input,
        select {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}

.form-inline .table-searchable tr.search-headers {
  .search-field {
    .operator {
      width: 50px;
      margin-right: 5px;
    }

    input[type="search"] {
      width: 100%;
      display: block;
    }
  }
}
