.judgeable {
  text-align: center;
  .confirmation-icon { margin-left: 8px; }
  input { margin-left: 20px; }
}

.task-comment {
  white-space: normal;
}

.judgments-version-history__version-content {
  display: flex;
  color: var(--#{$prefix}secondary);
}

.judgments-version-history__version-answer {
  margin-left: 5px;
}

.struck {
  text-decoration: line-through;
}
