#metrics-simulator * {
  z-index: 10;
}

.metrics-simulator__score-card {
  transform: translateX(-100%);
  z-index: 0;

  &--show {
    transition: transform 400ms ease-out;
    transform: translateX(0);
  }

  &--hide {
    transition: all 200ms ease-in;
    transform: scale(0);
    max-width: 0;
    opacity: 0;
  }

  .glyphicon.glyphicon-info-sign {
    top: 2px;
    position: relative;
  }
}

.metrics-simulator__score-container--ignored {
  @extend .text-muted;
}

.metrics-simulator__score--ignored {
  text-decoration: line-through;
}

.metrics-simulator__position {
  @extend .text-muted;
  @extend .align-self-center;
  
  width: 14px;
  text-align: right;
  display: inline-block;
  transform: translateX(-7px);
}
