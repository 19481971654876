@use 'sass:math';

.form-horizontal {
  .col-form-label {
    @extend .text-md-end;
    font-weight: $font-weight-bold;
  }
}

.input-group {
  &:has(.predicate) { min-width: 160px; }

  .predicate {
    flex-grow: 0;
    width: auto;
  }
}

.input-group-text .glyphicon {
  line-height: unset;
}

input[type="text"].form-control-plaintext:disabled {
  -webkit-text-fill-color: $gray-900;
  opacity: 1;
}

.form-check {
  margin: 10px 0;
}

.form-check-inline {
  margin: 0 10px;
}

.has-error {
  .form-text,
  .col-form-label,
  .form-check,
  &.form-check label {
    color: $form-feedback-invalid-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control,
  .form-select {
    border-color: $form-invalid-border-color;
    &:focus {
      border-color: darken($form-invalid-border-color, 10%);
      box-shadow: 0 0 6px lighten($form-invalid-border-color, 20%);
    }
  }
  // Set validation states also for addons
  .input-group .input-group-text {
    color: $form-feedback-invalid-color;
    background-color: $danger-bg-subtle;
    border-color: $form-invalid-border-color;
  }
  // Optional feedback icon
  .form-control-feedback {
    color: $form-feedback-invalid-color;
  }
}

[data-evaluation-type="app_review_summaries_evaluation"] textarea {
  height: 100px;
}
