@import "navbar";

.navbar.sticky-top {
  z-index: $zindex-sticky + 1;
}

// TODO: remove after upgrading to BS5
.sticky-xl-top {
  @include media-breakpoint-up(xl) {
    position: sticky;
    top: 0;
    z-index: $zindex-sticky;
  }
}

.table-sm {
  th.sticky-xl-top {
    @include media-breakpoint-up(xl) {
      top: $navbar-height - ($table-cell-padding-y-sm);
      padding-top: $table-cell-padding-y-sm * 3;
      box-shadow: 0 0.5px 0px 0px rgba(0, 0, 0, 0.1);
    }
  }
}

.sticky-top--themed {
  background-color: $body-bg;
  border-bottom: 1px solid $border-color;

}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: $zindex-sticky;
}

.sticky-bottom--themed {
  border-top: 1px solid $border-color;
  background-color: $body-bg;
}

.sticky-left {
  position: sticky;
  left: 0;
  z-index: $zindex-sticky;
}
