.lpa-dashboard {
  background: $dashboard-template-bg-color;
  margin-top: -21px;

  &__line-of-business-icon {
    max-width: 100%;
    max-height: 48px;
  }

  &__title {
    font-weight: $font-weight-bold;
  }

  &__title-sub {
    color: $dashboard-template-font-dark-gray;
  }

  &__content-block {
    background-color: var(--#{$prefix}body-bg);
    padding: 2rem;

    &-title {
      font-weight: $font-weight-bold;
      padding: 0 2rem;
    }

    &-body {
      color: $dashboard-template-font-dark-gray;

      ol {
        margin-bottom: 0;

        li:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }

      .text-truncate-multi-line {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
      }
    }
  }

  &__primary-patterns-chart-wrapper {
    margin-top: 3rem;
  }

  .metadata-chart {
    height: 290px;
  }
}
