.fields-container {
  .remove-fields-container {
    padding-top: 7px;

    .remove-question,
    .remove-answer {
      color: $dark;
      cursor: pointer;
    }
  }
}
