.mvw-search-baseline {
  .list-inline-item {
    &:not(:last-child)::after {
      content: '|';
      display: inline-block;
      margin-left: $list-inline-padding;
      opacity: 0.25;
    }

    .glyphicon-remove { top: 2px; }
  }
}
