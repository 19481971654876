.dashboard-template {
  --component-background-color: var(--#{$prefix}body-bg);

  &__content-wrapper {
    margin-top: 45px;
  }

  &__content-block {
    background-color: var(--component-background-color);
    @extend .pt-4;
    @extend .pb-4;
    @extend .px-4;

    &-title {
      font-weight: $font-weight-bold;
      font-size: $h3-font-size;

      @extend .pt-3;
      @extend .pb-1;
      @extend .px-3;
    }

    &-title-external {
      font-weight: $font-weight-bold;
      font-size: $h3-font-size;
      margin-top: 0px;

      @extend .ms-3;
      @extend .px-4;
      @extend .pb-3;
    }

    &-body {
      @extend .px-3;
      font-size: $font-size-base;
      color: $dashboard-template-font-dark-gray;

      p {
        line-height: revert;
      }
    }

    &-fixed-height {
      min-height: 200px;
    }
  }
}
