table.scrape-input-comparisons {

  th.input,
  td.input {
    width: 300px;
  }

  th.score,
  td.score {
    width: 100px;
    text-align: right;
  }

  td.score {
    @include tabular-numerals;
  }
}

.comparison-progress {
  margin-top: 50px;

  .glyphicon {
    margin-right: 5px;
  }
}

#comparison-summary {

  th.row-header,
  td.row-header {
    width: 15%;
    text-align: right;
    padding-right: 10px;
  }

  th.score,
  td.score,
  th.percent-judged,
  td.percent-judged {
    width: 15%;
    text-align: right;
  }
}

#scrape-input-comparison {
  th {
    font-size: $font-size-lg;
    font-weight: $font-weight-normal;
  }

  tr:first-child th {
    border-bottom: 0;
  }

  th.position,
  td.position {
    width: 4%;
  }

  td.position,
  td.position a {
    color: $gray-600;
  }

  th.baseline-output-name,
  td.baseline-output-name,
  th.comparison-output-name,
  td.comparison-output-name {
    width: 38%;
    white-space: normal;
  }

  th.baseline-score,
  td.baseline-score,
  th.comparison-score,
  td.comparison-score {
    width: 10%;
    @include tabular-numerals;
  }

  th.baseline-score,
  td.baseline-score {
    text-align: right;
    border-right: 1px $table-border-color solid;
  }
}

#differences-by-bucket,
#differences-by-pair {
  height: 350px;
}

#differences-by-pair {
  .apexcharts-marker {
    cursor: pointer;
  }
}

#comparison-metrics {
  .row {
    margin-bottom: 30px;
  }

  .metric-block {
    border: 1px solid $border-color;
    border-radius: 12px;
  }

  @each $_key, $_value in (
    'positive': 'success',
    'negative': 'danger',
    'neutral': 'secondary',
  ) {
    .#{$_key} {
      background-color: var(--#{$prefix}#{$_value}-bg-subtle);
      border-color: var(--#{$prefix}#{$_value}-border-subtle);
    }
  }

  .metric-text {
    font-size: $h4-font-size;
    font-weight: $font-weight-medium;
    margin-top: 15px;
    margin-bottom: 0px;
  }
}

.comparisons.show,
.comparisons.index,
.iteration_comparisons.index {
  .significance-positive {
    font-weight: $font-weight-bold;
    color: var(--#{$prefix}success);
  }

  .significance-negative {
    font-weight: $font-weight-bold;
    color: var(--#{$prefix}danger);
  }

  .significance-none {
    font-weight: $font-weight-bold;
  }
}
