.pages.home {
  .card .content-wrapper {
    overflow: auto;

    @include media-breakpoint-up(md) {
      height: 250px;
    }
  }

  #eligible-task-widget p:first-child {
    @extend .mt-3;
  }
}
