$hamburger-breakpoint--off: lg;
$navbar-padding--tb: 5px;
$navbar-padding--lr: 15px;
$navbar-height: 3.5rem;

html {
  scroll-padding-top: 65px;
}

#main-nav {
  @include media-breakpoint-down($hamburger-breakpoint--off) {
    @supports (position: sticky) {
      max-height: 100vh;
      overflow-y: scroll;

      .navbar-collapse.show {
        @at-root body:has(&) {
          overflow: hidden;

          &::before {
            content: '';
            position: absolute;
            inset: 0;
            background: rgba($offcanvas-backdrop-bg, $offcanvas-backdrop-opacity);
            z-index: $zindex-sticky - 1;
            transition: .15s ease-in-out;
          }
        }
      }
    }
  }
}

.navbar {
  min-height: $navbar-height;
  @extend .mb-4;

  @include media-breakpoint-down($hamburger-breakpoint--off) {
    .navbar-collapse {
      display: flex;
      flex-direction: column;

      & > * { width: 100%; }

      form[name="search"] {
        order: -1;
        padding: ($navbar-padding--tb * 2) $navbar-padding--lr;
      }
    }

    .navbar-collapse:not(.show, .collapsing) {
      display: none;
    }
  }
}

.navbar__container {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  @extend .flex-grow-1;
  @extend .flex-wrap;
  padding: ($navbar-padding--tb * 2) $navbar-padding--lr;

  @include media-breakpoint-up($hamburger-breakpoint--off) {
    padding: 0 $navbar-padding--lr;
  }
}

.navbar__admin-group {
  @extend .flex-grow-1;
  @extend .justify-content-lg-end;
}

.logo {
  vertical-align: sub;
}

.dropdown-toggle:after {
  margin-left: 0.5em;
}

.dropdown-header {
  font-weight: $font-weight-bold;
  color: $gray-800;
}

#global-dropdown {
  .dropdown-menu {
    @extend .mt-0;
  }
}

// padding rules maintain visual hierarchy of nested folders
.navbar-nav {
  .dropdown-menu {
    @extend .mt-0;
    max-width: 100vw;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    @include media-breakpoint-up($hamburger-breakpoint--off) {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    }

    @include media-breakpoint-up(lg) {
      max-height: 85vh;
      overflow-y: auto;
    }
  }

  .dropdown-item, .dropdown-header {
    @extend .text-truncate;
    max-width: 100vw;
    padding: $navbar-padding--tb ($navbar-padding--lr * 2);

    @include media-breakpoint-up($hamburger-breakpoint--off) {
      padding: $navbar-padding--tb $navbar-padding--lr;
    }

    &__no-image {
      @extend .ps-2;
      margin-left: 20px;
    }
  }

  .dropdown-item.dropdown-drawer__parent {
    padding: $navbar-padding--tb $navbar-padding--lr;

    @include media-breakpoint-up($hamburger-breakpoint--off) {
      padding: $navbar-padding--tb 0;
    }
  }

  .dropdown-drawer__child-list .dropdown-item {
    padding: $navbar-padding--tb ($navbar-padding--lr * 2.75);

    @include media-breakpoint-up($hamburger-breakpoint--off) {
      padding: $navbar-padding--tb ($navbar-padding--lr * 2.25)
    }
  }
}

// when the hamburger menu is active, this allows the background shadow for clicked items to span
// the width of the entire page
.navbar-collapse {
  margin: 10px -15px;

  @include media-breakpoint-up($hamburger-breakpoint--off) {
    margin: 0;
  }
}

// mobile rules here are designed to make navbar items easier to click
.navbar-expand-lg .navbar-nav {
  .nav-link, .navbar-text {
    padding: ($navbar-padding--tb * 2) ($navbar-padding--lr * 2);

    @include media-breakpoint-up($hamburger-breakpoint--off) {
      padding: ($navbar-padding--tb * 3) ($navbar-padding--lr * 0.5);
    }

    @include media-breakpoint-up(xl) {
      padding: ($navbar-padding--tb * 3) ($navbar-padding--lr);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .nav-link, .dropdown-item, .navbar-text, .dropdown-header {
    font-size: $h5-font-size;

    &:focus, &.show {
      outline: none;
      background: color-mix(in srgb, currentColor 5%, transparent);
    }

    @include media-breakpoint-up($hamburger-breakpoint--off) {
      font-size: $font-size-base;
    }
  }
}
