// Style Range slider

.elc__range {
  --slider-track-bg: var(--#{$prefix}gray-400);
  --slider-thumb-bg: var(--#{$prefix}body-bg);

  vertical-align: middle;
  -webkit-appearance: none;
}

.elc__range:focus {
  outline: none;
}

.elc__range::-webkit-slider-runnable-track {
 background: var(--slider-track-bg);
 height: 2px;
}

.elc__range::-moz-range-track {
 background: var(--slider-track-bg);
 height: 2px;
}

.elc__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 13px;
  width: 13px;
  background: var(--slider-thumb-bg);
  margin-top: -5px;
  border-radius: 50%;
  border: 1px solid var(--slider-track-bg);
}

.elc__range::-moz-range-thumb {
  height: 13px;
  width: 13px;
  background: var(--slider-thumb-bg);
  margin-top: -5px;
  border-radius: 50%;
  border: 1px solid var(--slider-track-bg);
}

.elc__results, .elc__frequentist-counts-hero {
  background-color: var(--#{$prefix}light);
  padding: 30px;
  margin-top: 20px;
  border: solid var(--slider-track-bg) 1px;
  display: inline-block;
  width: 100%;
}

.elc__results-days {
  font-weight: $font-weight-bold;
  font-size: xx-large;
}

.elc__frequentist-counts-hero-body {
  font-weight: $font-weight-bold;
}

.elc-hide {
  display: none !important;
}

#elc-form, #elc-filters {
  .widget-filters {
    .operator {
      display: none;
    }

    .nested-fields {
      position: relative;

      .remove_fields {
        color: var(--#{$prefix}secondary);
        position: absolute;
        top: 40px;
        right: -20px;
      }
    }
  }
}
