.dropdown-toggle {
  &::after {
    vertical-align: middle;
  }
}

.dropdown-drawer__parent {
  border-bottom: 1px solid transparent;
  padding-left: 0;
  transition: border-bottom-color 0ms linear 300ms;
  display: flex;
  align-items: center;
  cursor: pointer;

  &--open {
    background-color: $dropdown-link-hover-bg;
    border-bottom-color: $dropdown-divider-bg;
    transition: border-bottom-color 0ms linear;
  }

  .glyphicon-menu-right {
    padding: 0 $icon-size-sm;
    font-size: $icon-size-sm;
    transition: transform 300ms ease-in-out;

    &--open {
      transform: rotate(90deg);
    }
  }
}

.dropdown-drawer__child-list {
  max-height: 0px;
  overflow-y: hidden;
  list-style: none;
  padding-inline-start: 0;
  transition: max-height 300ms ease-in-out;

  .dropdown-item {
    padding-left: 2.5rem;
  }

  &--open {
    max-height: unset;
    transition-duration: 500ms;
    border-bottom-color: $dropdown-divider-bg;
  }
}

.dropdown-submenu {
  .dropdown-toggle {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    &::after {
      margin-left: 1em;
    }
  }

  .dropdown-menu {
    top: -1px;
    left: 100%;
    margin-left: .1rem;
    margin-right: .1rem;
  }
}

