.device_experiments.index {
  .list-group {
    border-top: 1px solid $list-group-border-color;
    max-height: 50vh;
    overflow-y: scroll;
  }
  .list-group-item:first-child {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  h1 > small {
    display: block;
  }
}

.device_experiments.show {
  .treatment-table {
    &__table {
      td {
        vertical-align: middle !important;
      }

      .btn {
        padding: 2px 12px;
      }
    }

    &__memo {
      @extend .text-center;
      line-height: 16px;
    }

    &__name-td {
      @include media-breakpoint-down(lg) {
        max-width: 50vw;
        text-overflow: ellipsis;
      }
    }
  }

  .experiment-info {
    &__container {
      margin: 2rem auto;

      @include media-breakpoint-down(lg) {
          margin: 1.5rem auto;
      }
    }
  }
}


