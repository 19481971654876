.experiment_priorities.index {
  *:not(input:focus)::selection {
    background: transparent;
  }

  tr {
    &:not(.loading) .visible-on-loading { display: none; }
    &.loading .hidden-on-loading { display: none; }
  }
}
