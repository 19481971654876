form.edit-spike-check-strategy {
  .widget-filters label {
    display: none;
  }

  .nested-fields {
    margin-bottom: 20px;

    a.remove_fields {
      color: $dark;
      margin-top: 6px;
      margin-left: 4px;
    }

    .col-form-label {
      font-weight: $font-weight-normal;
      display: inline-block;
      text-align: right;
    }
  }

  .nested-fields:not(.nested-fields--bootstrap) {
    .col-form-label {
      width: 25%;
    }

    .form-control,
    .form-select {
      &[data-controller="form--select2"],
      &.model {
        width: 50%;

        + .select2-container {
          max-width: 50%;
        }
      }

      &.string {
        width: calc(60% - 20px);
      }

      &.operator {
        width: auto;
        margin-top: 1px;
      }

      &.numeric {
        width: 20%;
      }
    }

    .numeric,
    .model,
    .operator,
    .string,
    .select2-container--bootstrap {
      display: inline-block;
    }
  }

  .widget-filters {
    .col-form-label {
      margin-right: 5px;
    }

    .dropdown {
      display: inline-block;
    }
  }

  .col-form-label {
    font-weight: $font-weight-bold;
  }

  .measures .measures-fields {
    max-height: 235px;
    overflow-y: auto;
    margin-bottom: 20px;

    .nested-fields {
      &.last, &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
