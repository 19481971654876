.reader-view {
  &__label {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    color: $text-muted;
    font-size: $small-font-size;
  }

  &__icon {
    display: flex;
    align-items: center;

    img.gray {
      // Setup target color for SVG's as #8B8B8B
      filter: invert(60%) sepia(2%) saturate(2%) hue-rotate(336deg) brightness(91%) contrast(88%);
    }

    img.blue {
      // Setup target color for SVG's as #425BA7
      filter: invert(39%) sepia(8%) saturate(5609%) hue-rotate(191deg) brightness(83%) contrast(83%);
    }
  }
}
