[data-evaluation-type="personalized_llm_playlist_v2_evaluation"] {
  @at-root body.tasks & {
    .assignment-info {
      display: none;
    }

    #steps-progress {
      --spacer: #{map-get($spacers, 2)};
      --count-size: 32px;
      --divider-height: 5px;
      --active-bg: var(--#{$prefix}primary);
      --active-color: var(--#{$prefix}white);
      --inactive-bg: var(--#{$prefix}border-color);
      --inactive-color: var(--#{$prefix}body-color);

      display: grid;
      grid-template-columns: repeat(
        auto-fit,
        var(--count-size)
        minmax(var(--count-size), 1fr)
      );
      gap: var(--spacer);
      counter-reset: steps-counter;

      .step-tooltip {
        --bs-tooltip-bg: var(--inactive-bg);
        --bs-tooltip-color: var(--inactive-color);
        font-size: clamp($tooltip-font-size * 0.8, 3vw, $tooltip-font-size);
        max-width: 150px;

        &.filled {
          --bs-tooltip-bg: var(--active-bg);
          --bs-tooltip-color: var(--active-color);
        }
      }

      .step {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &.filled {
          .divider,
          .count {
            background: var(--active-bg);
            color: var(--active-color);
          }
        }

        .divider {
          height: var(--divider-height);
          flex: 1;
          background: var(--inactive-bg);
          border-radius: $border-radius;
        }

        .count {
          counter-increment: steps-counter;
          position: relative;
          display: flex;
          width: clamp(var(--count-size) * 0.8, 3vw, var(--count-size));
          border-radius: 50%;
          background: var(--inactive-bg);
          color: var(--inactive-color);
          font-size: clamp($font-size-base * 0.8, 3vw, $font-size-base);

          &::before {
            content: '';
            padding-top: 100%;
          }

          &::after {
            content: counter(steps-counter);
            position: absolute;
            inset: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
