body.ab_experiments,
body.ab_treatments {
  .experiment-name {
    width: 25%;
  }

  .experiment-starts-at,
  .experiment-ends-at {
    width: 10%;
  }

  .experiment-bucket-count,
  .actions {
    width: 5%;
  }
}

.etl-dimensions {
  &__container {
    @extend .row;
    @extend .d-flex;
    @extend .flex-wrap;
    @extend .flex-column;
    @extend .list-group;
    @extend .mb-5;

    @include media-breakpoint-up(lg) {
      max-height: 70vh;
      overflow-y: scroll;
    }
  }

  &__link-container {
    @extend .col-12;
    @extend .col-lg-3;
  }

  &__link {
    @extend .list-group-item;
    margin: 0.25rem;
    color: $link-color;

    @include media-breakpoint-up(lg) {
      padding: 0.5rem;

      + .etl-dimensions__link {
        border-top-width: 1px;
      }
    }
  }
}
