// Referenced from vendor/assets/stylesheets/bootstrap-glyphicons.css
.glyphicon-lpa-example-widget { @extend .glyphicon-th-list; }

.portlet .lpa-example-widget {
  height: 100%;
  overflow-y: scroll;

  .lpa-task-assigned-link {
    pointer-events: none;
    color: initial;
  }
}
