.dashboard-template__chart {
  background-color: var(--#{$prefix}body-bg);

  .apexcharts-canvas {
    margin: 0 0 !important;
  }

  &-container {
    position: relative;
    top: 0;
    bottom: 65px;
    left: 0;
    right: 0;
  }
}
