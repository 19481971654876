@import 'navbar';
@import 'bootstrap/scss/mixins/clearfix';

$zindex-navbar: $zindex-sticky + 1;
$zindex-dashboard-header: $zindex-navbar - 1;
$zindex-actions: $zindex-navbar - 2;
$zindex-actions-clickable-elements: $zindex-navbar;

$actions-y-offset: 3.25rem;

.dashboard-menu {
  @extend .d-flex;
  @extend .align-items-center;

  height: $navbar-height;
  box-sizing: content-box;
  padding: 0.5rem 1rem;

  .toolbar-icon {
    @extend .me-2;
    $zindex-actions-clickable-elements: $zindex-navbar;

    cursor: pointer;
    font-size: $icon-size-lg;
    color: var(--#{$prefix}secondary-color);
  }
}

.dashboard {
  position: relative;

  #dashboard-header {
    @extend .text-center;
    @extend .col-12, .col-md-10, .offset-md-1, .col-lg-12, .offset-lg-0;

    @include media-breakpoint-up(lg) {
      position: absolute;
      height: 0;
      overflow-y: visible;
      top: -1 * $actions-y-offset;
      z-index: $zindex-dashboard-header;
    }

    form {
      display: none;
    }
  }

  .actions.sticky-top {
    top: $navbar-height;
    z-index: $zindex-actions;

    margin-top: $actions-y-offset;
    background: $white;
    border-bottom: 1px solid $border-color;

    .dropdown-menu .glyphicon {
      @extend .me-1;
    }
  }

  &__configuration-dropdown {
    @extend .dropdown, .me-2, .d-none;
    z-index: $zindex-actions-clickable-elements;
  }

  &.configurable {
    #configure-dashboard {
      text-shadow: 0 2px 3px $gray-700;
    }

    .dashboard__configuration-dropdown {
      @extend .d-inline-block;
    }

    &.editable {
      #dashboard-header {
        #dashboard-name {
          display: none;
        }

        form {
          display: initial;
        }
      }
    }
  }

  .popover-container {
    .popover {
      @extend .w-100, .mb-3;

      .form-container {
        @include clearfix;
      }

      .dropdown {
        z-index: 1200;
      }
    }

    #portlet_widget_attributes_page_length, #portlet_widget_attributes_limit {
      width: 65px;
      @extend .pe-1;
    }
  }
}

.dashboards.show {
  .select2-container {
    z-index: 1100;
  }
}

.dashboards.edit {
  .alert ul {
    margin-top: 5px;
  }
}

.toggle-favorite-dashboard {
  cursor: pointer;
}

.glyphicon-not-a-favorite {
  @extend .glyphicon-heart-empty;
  @extend .text-muted;
}

.glyphicon-favorite {
  @extend .glyphicon-heart;
  @extend .text-muted;
}
