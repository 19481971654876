svg {
  &.uts-closed_captions { aspect-ratio: 26 / 15; }
  &.uts-ad { aspect-ratio: 26 / 15; }
  &.uts-dolby_atmos { aspect-ratio: 52 / 15; }
  &.uts-dolby_vision { aspect-ratio: 52 / 15; }
  &.uts-4k { aspect-ratio: 26 / 15; }
  &.uts-hd { aspect-ratio: 26 / 15; }
  &.uts-hdr10 { aspect-ratio: 54 / 15; }
  &.uts-sdh { aspect-ratio: 32 / 15; }
  &.uts-sdr { aspect-ratio: 26 / 15; }
}
