[data-character-counter] {
  .counts {
    margin-top: map-get($spacers, 2);
    text-align: right;
    font-size: $small-font-size;
    color: $text-muted;
    display: none;

    &:has([data-character-counter-target="character-count"]:not(:empty)) {
      display: block;
    }
  }
}
