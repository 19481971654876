$plan-experiment-status-success: var(--#{$prefix}success);
$plan-experiment-status-danger: var(--#{$prefix}danger);

.plan-experiment {

  &-status-icon {
    width: 18px;
  }

  &-status {
    @extend .fw-bold;
  }

  &-status-pending,
  &-status-running,
  &-status-paused {
    color: $gray-600;

    .plan-experiment-status-icon {
      filter: invert(60%) sepia(2%) saturate(2%) hue-rotate(336deg) brightness(91%) contrast(88%);
    }
  }

  &-status-approved,
  &-status-finished {
    color: $plan-experiment-status-success;

    .plan-experiment-status-icon {
      filter: invert(29%) sepia(19%) saturate(5915%) hue-rotate(116deg) brightness(96%) contrast(105%);
    }
  }

  &-status-cancelled {
    color: $plan-experiment-status-danger;

    .plan-experiment-status-icon {
      filter: invert(49%) sepia(71%) saturate(5397%) hue-rotate(339deg) brightness(97%) contrast(111%);
    }
  }

  &-title-with-anchor-link {
    width: 100%;
    a {
      display: none
    }

    &:hover {
      a {
        display: inline;
      }
    }
  }
}

#plans-experiment-sizing-section #elc-filters {
  .widget-filters-list {
    .col-form-label {
      @extend .page-bem__light-text
    }
  }
  .col-form-label {
    @extend .fw-bold
  }
}
