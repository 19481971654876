$presentation-background-color: $dark;
$presentation-border-color: var(--#{$prefix}border-color);

@import 'templates/colors';

body.presentation {
  background-color: $black;

  #dashboard-header {
    position: static;
    height: initial;
    @extend .mt-5;
    @extend .mb-2;

    a {
      color: $white;
      max-width: 80vw;

      &:hover {
        text-decoration: none;
      }

      .dashboard-template__icon img {
        filter: invert(37%) sepia(80%) saturate(3176%) hue-rotate(199deg) brightness(104%) contrast(103%);
      }
    }
  }

  .widget {
    box-shadow: none;
  }

  .tabular-report-widget {
    .table {
      background-color: $presentation-background-color;
      border-color: $presentation-border-color;

      caption,
      tr,
      th,
      tr:not(.search-headers) th,
      td {
        color: $white;
        background-color: $presentation-background-color;
        border-color: $presentation-border-color !important;
      }

      &.dataTable {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }

    .dataTables_paginate {
      .page-link {
        background-color: $black;
        border-color: $presentation-border-color;
        color: $white;
      }

      .disabled .page-link {
        color: $dashboard-template-font-light-gray;
      }

      .active .page-link {
        background-color: $presentation-background-color;
      }
    }

    .dataTables_length select {
      background-color: $black;
      border-color: $presentation-border-color;
      color: $white;
    }
  }

  .portlet .configuration,
  .ab-experiment-chart-readout-widget form.new_brix_ad_hoc_filter,
  .tabular-report-widget .truncation-message,
  .tabular-report-widget .toggle-significance,
  .dataTables_filter {
    display: none;
  }

  table.dataTable.table-sm thead {
    .sorting:before,
    .sorting_asc:before,
    .sorting_desc:before,
    .sorting_asc_disabled:before,
    .sorting_desc_disabled:before,
    .sorting:after,
    .sorting_asc:after,
    .sorting_desc:after,
    .sorting_asc_disabled:after,
    .sorting_desc_disabled:after {
      display: none;
    }

    tr th {
      padding-right: 0.3rem;
    }
  }

  .portlet .tabular-report-widget .table .title {
    @extend .m-0;
    @extend .py-3;
  }

  .dashboard-template {
    margin-top: 0;
    background-color: $black;

    &__title-sub {
      color: $white;
    }

    &__content-block {
      background-color: $presentation-background-color;

      &-body {
        color: $white;
      }
    }

    .result-component__measure-tooltip,
    &__info-block-filters,
    &__admin-links {
      display: none;
    }

    &__table-wrapper,
    &__chart {
      background-color: $presentation-background-color;
    }

    &__table {
      color: $white;
    }

    &__table-light-border,
    &__table-title {
      border-color: $presentation-border-color;

      td {
        border-color: $presentation-border-color;
      }
    }

    &__status {
      color: $dashboard-template-font-light-gray;
    }

    &__launched,
    &__finished {
      .dashboard-template__icon-status img {
        filter: invert(28%) sepia(40%) saturate(6491%) hue-rotate(131deg) brightness(102%) contrast(101%);
      }
    }

    &__rejected {
      .dashboard-template__icon-status img {
        filter: invert(100%) sepia(7%) saturate(647%) hue-rotate(184deg) brightness(106%) contrast(100%);
      }
    }

    &__cancelled {
      .dashboard-template__icon-status img {
        filter: invert(36%) sepia(51%) saturate(1294%) hue-rotate(324deg) brightness(114%) contrast(130%);
      }
    }
  }

  .lpa-dashboard {
    background-color: $black;
    color: $white;

    .border-top {
      border-color: $presentation-border-color !important;
    }

    &__title-sub {
      color: $white;
    }

    &__content-block {
      background-color: $presentation-background-color;

      &-body {
        color: $white;
      }
    }
  }

  .lpa-example-widget {
    .border-bottom,
    .border-left,
    .border-right {
      border-color: $presentation-border-color !important;
    }

    .key-value-table {
      border-color: $presentation-border-color;
      background-color: $presentation-background-color;

      & > .card-header {
        color: $white;
        background-color: $presentation-background-color;
        border-color: $presentation-border-color;

        .lpa-task-assigned-link {
          color: $white;
        }
      }

      .list-group {
        border-top: 1px solid $presentation-border-color;
      }

      .list-group-item {
        background-color: $presentation-background-color;
        border-color: $presentation-border-color;
      }
    }
  }
}
