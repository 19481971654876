// based off of http://getbootstrap.com/examples/sticky-footer-navbar
html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}
footer.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  min-height: 60px;
  background-color: var(--#{$prefix}light);
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;

  .text-muted {
    text-align: center;
    margin: 6px 0;
    font-size: $font-size-xs;
  }
}
