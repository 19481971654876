body.favorites.index {
  --background-color: var(--#{$prefix}tertiary-bg);
  --component-background-color: var(--#{$prefix}body-bg);

  background-color: var(--background-color);

  .favorite,
  .block-link {
    background-color: var(--component-background-color);
    border-radius: $border-radius;
  }

  .favorite {
    [data-bs-toggle="dropdown"]:has(img) {
      cursor: pointer;
    }
  }
}

.favorites-my-audits,
.favorites-lpa-tasks,
.favorites-my-tasks,
.favorites-endpoint-diff-status,
.favorites-endpoint-diff-status-completed,
.favorites-scrape-status,
.favorites-scrape-status-completed {
  width: 100%;
  tr {
    border-top: 1px solid var(--#{$prefix}border-color);
  }
  tr:last-child {
    border-bottom: 1px solid var(--#{$prefix}border-color);
  }
  td {
    padding: 15px 10px;
  }
  .glyphicon-user {
    font-size: $font-size-xs;
  }
  @include media-breakpoint-up(sm) {
    .truncate-oversize-column {
      max-width: 100px;
    }
  }
}

.favorites-endpoint-diff-status,
.favorites-scrape-status {
  tr:first-child {
    border-top: none;
  }
}
