.organizations.show {
  .form-inline {
    @extend .mt-2;
    @extend .mb-2;

    .select2-container {
      @extend .mb-2;
      @extend .me-2;
    }
    .btn-primary {
      @extend .mb-2;
    }
  }

  .table-responsive {
    @include media-breakpoint-down(xl) {
      max-height: 50vh;
      margin-bottom: 2rem;
    }
  }

  th, td {
    &.other-orgs {
      text-align: right;
    }
  }

  #organization-users {
    th.name,
    td.name {
      width: 50%;
    }

    th.role,
    td.role {
      width: 20%;
    }

    th.user-active,
    td.user-active {
      width: 10%;
      text-align: center;
    }
  }

  #organization-evaluations {
    th.name,
    td.name {
      width: 40%;
    }

    th.type,
    td.type {
      width: 30%;
    }

    th.evaluation-active,
    td.evaluation-active {
      width: 10%;
      text-align: center;
    }
  }

  #organization-dashboards {
    th.name, td.name {
      width: 66%;
    }
  }
}
