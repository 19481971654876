.input-metadata {
  .card-header { text-align: center; }

  .table {
    th, td {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }

    td {
      @extend .break-overflow-text;
      white-space: normal;
      overflow: visible;
    }

    .left-td {
      padding-right: 2px;
      text-align: right;
      width: 50%;
    }
  }
}

.key-value-table {
  @extend .input-metadata;
  @extend .card;
  @extend .mt-4;
  @extend .mb-4;

  .card-header {
    font-weight: $font-weight-bold;
  }

  .key {
    hyphens: auto;
  }

  &__header {
    @extend .card-header;
    font-weight: $font-weight-bold;
  }
}

.metadata-table {
  th.media-metadata-boolean,
  td.media-metadata-boolean {
    text-align: center;
  }

  th.media-metadata-int,
  td.media-metadata-int,
  th.media-metadata-bigint,
  td.media-metadata-bigint,
  th.media-metadata-percentage,
  td.media-metadata-percentage {
    text-align: right;
  }

  td.media-metadata-int,
  td.media-metadata-bigint,
  td.media-metadata-percentage {
    @include tabular-numerals;
  }

  th.media-metadata-date,
  td.media-metadata-date {
    text-align: center;
  }
}

.metadata-chart {
  width: 100%;
  height: 250px;
}
