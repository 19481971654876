.daterangepicker {
  .ranges ul li {
    color: $primary;

    &.active {
      background-color: $primary;
    }
  }

  td.active {
    background-color: $primary;
  }
}
