.priority-input-group.input-group {
  max-width: 100px;

  input {
    min-width: 60px;
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    & + .spinners {
      display: flex;
      flex-direction: column;
      font-size: 9px;
      padding: 0;

      a {
        cursor: pointer;
        width: 20px;

        &:first-child {
          border-bottom: 1px solid var(--#{$prefix}border-color);
        }
      }
    }
  }
}
