table#scrapes {

  th.owner,
  td.owner {
    width: 15%;
  }
}

table#listed-scrapes {
  .evaluation-name {
    width: 15%;
  }

  .experiment-name {
    // width: 15%;
  }

  .date {
    width: 15%;
  }

  .owner {
    width: 15%;
  }

  .judgeable {
    width: 10%;
    text-align: center;
  }

  .tasks {
    width: 24%;
  }
}

.description-edit {
  &__icon {
    @extend .me-2;
    cursor: pointer;
    text-decoration: none;
    color: $gray-700;
  }
}

.scrapes.index {
  .tooltip {
    @extend .tooltip--with-line-breaks;

    .tooltip-inner {
      text-align: left;
      max-width: 300px;
      padding: 1rem;
    }
  }

  .checkbox {
    text-align: center;

    .confirmation-icon {
      margin-left: 8px;
    }

    input {
      margin-left: 20px;
    }
  }

  .significance-positive {
    fill: var(--#{$prefix}success);
  }

  .significance-negative {
    fill: var(--#{$prefix}danger);
  }
}
