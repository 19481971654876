#schedules {
  th.evaluation,
  td.evaluation {
    width: 15%;
  }

  th.schedulable-name,
  td.schedulable-name {
    width: 20%;
  }

  th.schedulable-type,
  td.schedulable-type {
    width: 15%;
  }

  th.schedule-active,
  td.schedule-active {
    width: 10%;
    text-align: center;
  }

  th.days,
  td.days {
    width: 20%;
  }

  th.even-weeks,
  td.even-weeks {
    width: 10%;
    text-align: center;
  }

  th.odd-weeks,
  td.odd-weeks {
    width: 10%;
    text-align: center;
  }
}
