.tooltip-inner {
  ul {
    padding: 0 0 0 1em;
    margin: 0;
  }
  max-width: 500px;
}

.tooltip--with-line-breaks {
  .tooltip-inner {
    white-space: pre-line;
  }
}

.tooltip.tooltip-danger {
  --bs-tooltip-bg: var(--#{$prefix}danger);
  --bs-tooltip-color: var(--#{$prefix}white);
}
