.curl-snippet-component {
  position: relative;

  pre { padding-top: 32px; }

  a[data-controller="copy-button"] {
    position: absolute;
    right: 8px;
    top: 8px;
  }
}
