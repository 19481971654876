.breadcrumbs {
  display: flex;

  .crumb-group {
    &:last-of-type .separator {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 5px;
    }
  }

  .crumb {
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .separator {
    padding: 0 10px;
    font-size: $icon-size-sm;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}
