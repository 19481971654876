$z--entry: 2;
$z--entry-previous-status-indicator: $z--entry + 1;
$z--entry-text-container: $z--entry-previous-status-indicator + 1;

$height--entry: 40px;
$border-radius--entry: 45px;

.with-inactive-gradient {
  background-image: linear-gradient(to right top, #555555, #5d5d5d, #666666, #6e6e6e, #777777);
}
.with-active-gradient {
  background-image: linear-gradient(to right, #2ca02c, #2ca72c, #2caf2c, #2cb62c, #2cbe2c);
}

.timeline__root {
  background-color: $body-tertiary-bg;
  border: 1px solid $border-color;
  padding: 70px 20px 0px 0px;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  align-items: center;
}

#timeline__empty-chart {
  margin: 0 0 70px 15px;
}

.timeline__x-axis {
  overflow: visible !important;
  margin-left: 20px;
  margin-right: 20px;
}

.timeline__entry {
  @extend .with-inactive-gradient;

  z-index: $z--entry;
  height: $height--entry;
  margin-bottom: 20px;
  padding: 0 20px;
  position: relative;
  border-radius: $border-radius--entry;
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.2);
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    color: $white;
    text-decoration: none;
    box-shadow: 4px 4px 8px 0 rgba(0,0,0,0.3);
    cursor: pointer;
    transform: translatey(-3px);
  }

  &--running, &--approved {
    @extend .with-active-gradient;
  }
}

.timeline__entry-status-indicator + .tooltip {
  .tooltip-inner {
    width: 200px;
  }
}

.timeline__entry-text-container {
  // center the text vertically within the entries
  position: absolute;
  top: $height--entry * 0.5;
  display: flex;
  align-items: center;

  // height and width are set to shrink the size of the container to fix its text content
  // this prevents it from blockin the hover state on the previous status indicators, which have to sit behind it
  height: 0;
  width: inherit;

  z-index: $z--entry-text-container;
  padding: 0 20px;
  left: 0px;
  transition: all 0.2s ease;
}

.timeline__entry-text {
  @extend .text-truncate;
  font-size: $font-size-base;
  color: $white;
  margin: 0;
}

.timeline__entry-hover-date {
  position: absolute;
  display: flex;
  align-items: center;
  width: 200px;
  top: 11px;
  visibility: hidden;

  &--starts-at {
    left: -200px;
    text-align: right;
    justify-content: flex-end;
    padding-right: 10px;
  }
  &--ends-at {
    right: -200px;
    text-align: right;
    justify-content: flex-start;
    padding-left: 10px;
  }
  &--visible {
    visibility: visible;
  }
}

.timeline__entry-hover-date > p {
  font-size: $font-size-sm;
  color: var(--#{$prefix}body-color);
  font-weight: $font-weight-bold;
  margin: 0;
}

.timeline__entry-previous-status-period {
  @extend .with-inactive-gradient;

  position: absolute;
  top: 0;
  left: 0;
  z-index: $z--entry-previous-status-indicator;
  height: $height--entry;

  &--coinciding-with-starts-at {
    border-top-left-radius: $border-radius--entry;
    border-bottom-left-radius: $border-radius--entry;
  }

  &--coinciding-with-ends-at {
    // allows status indicators to inherit the scroll animation of their parent entry
    // because we set the left property dynamically inline at runtime with D3, we have to use important here
    left: unset !important;
    right: 0;
    border-top-right-radius: $border-radius--entry;
    border-bottom-right-radius: $border-radius--entry;
  }
}
