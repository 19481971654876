$page-bem-bg-color: var(--#{$prefix}tertiary-bg);
$page-bem-primary-blue: $primary;
$page-bem-primary-red: $danger;
$page-bem-font-dark-gray: var(--#{$prefix}body-color);
$page-bem-font-light-gray: var(--#{$prefix}secondary);

.page-bem {
  background: $page-bem-bg-color;
  margin-top: -21px;

  &__modal {
    .modal-content {
      border: none;
      border-radius: 0px;
      @extend .p-2;


      .modal-header {
        border: none;
        @extend .py-2;

        button {
          --bs-btn-close-opacity: 100%;
        }
      }

      .modal-body {
        @extend .py-2;
      }

      .modal-title {
        @extend .mt-2;
      }
    }
  }

  a:not(.btn) {
    color: $page-bem-primary-blue;
  }

  &__button {
    &-primary {
      @extend .px-3;
      @include button-variant($page-bem-primary-blue, $page-bem-primary-blue, white);

      &:hover {
        color: color-contrast($page-bem-primary-blue);
      }
    }

    &-secondary {
      @extend .px-3;
      @include button-variant(white, $page-bem-primary-blue, $page-bem-primary-blue);

      &:hover {
        color: $page-bem-primary-blue;
      }
    }

    &-danger {
      @extend .px-3;
      @include button-variant($page-bem-primary-red, $page-bem-primary-red, white);

      &:hover {
        color: color-contrast($page-bem-primary-red);
      }
    }
  }

  &__icon {
    &--inline {
      width: 1rem;
    }

    &--small {
      img {
        width: 15px !important;
      }
    }

    &--gray {
      // Setup target color for SVG's as #8B8B8B
      filter: invert(60%) sepia(2%) saturate(2%) hue-rotate(336deg) brightness(91%) contrast(88%);
    }
  }

  &__breadcrumbs {
    @extend .my-2;
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: $h1-font-size;
  }

  &__title-sub {
    font-size: $font-size-base;
    line-height: 20px;
    color: $page-bem-font-dark-gray;
  }

  &__box {
    background-color: var(--#{$prefix}body-bg);
    @extend .pt-4;
    @extend .pb-4;
    @extend .px-4;

    &-title--external {
      font-weight: $font-weight-bold;
      font-size: $h3-font-size;
      margin-top: 0px;

      @extend .pb-3;
    }

    &-body {
      @extend .px-3;
      @extend .pt-2;

      font-size: $font-size-base;
      color: $page-bem-font-dark-gray;

      p {
        line-height: revert;
      }
    }
  }

  &__text-medium {
    font-weight: $font-weight-medium;
  }

  &__text-normal {
    font-weight: $font-weight-normal;
  }

  &__text-bold {
    font-weight: $font-weight-bold;
  }

  &__light-text {
    color: $page-bem-font-light-gray;
  }

  .btn-review-requested {
    @include button-variant($gray-700, $gray-700);
    cursor: default;
  }

  table {
    th {
      color: $page-bem-font-light-gray;
    }
  }
}
