#input-task-counts {
  th.input-name,
  td.input-name {
    width: 25%;
  }

  .task-count {
    width: 10%;
  }
}

#scrape-counts {
  th.input-name,
  td.input-name {
    width: 25%;
  }

  .task-count {
    width: 20%;
  }
}
