@import "../portlets.scss";
@import "../gridstack.scss";

$widget-box-shadow: 0 0 5px rgba($black, 0.3);
$widget-box-shadow--anchored-widget-highlight: 0 0 20px rgba(255, 127, 3, 0.7);

$widget-title-padding: ($portlet-icon-size * 2);

@keyframes highlightAnchoredWidget {
    0% { box-shadow: $widget-box-shadow--anchored-widget-highlight; }
    100% { box-shadow: $widget-box-shadow; }
}

#new_portlet {
  .data-source-details {
    max-height: 310px;
    overflow-y: scroll;

    .list-group-item:first-child {
      border-radius: 0;
      border-top: 0;
    }
  }

  img {
    max-width: 100%;
  }
}

.widget {
  box-shadow: $widget-box-shadow;

  @extend .h-100, .w-100;
  min-height: unset !important;

  &__insert-title-placeholder-icon {
    @extend .ms-2;

    height: $icon-size-md;
    width: $icon-size-md;
    transform: scaleX(-1); // flip icon over horizontal axis

    cursor: pointer;
  }

  &__clear-title-input-icon {
    @extend .ms-2;

    @extend .glyphicon;
    @extend .glyphicon-remove;
    font-size: $icon-size-md;

    cursor: pointer;
  }
}

.portlet--anchored .widget {
  animation: highlightAnchoredWidget 5s linear;
}

.tooltip .tooltip-inner {
  @extend %disposition-color;
}
