.guidelines_acknowledgements.show {
  .flash.alert {
    margin-bottom: 0px;
  }

  .ack-button {
    position: sticky;
    bottom: 0px;
    background-color: $body-bg;
    z-index: 1020;
  }
}
