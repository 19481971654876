
$_component-color: var(--#{$prefix}body-color);

.timeline__date-bar {
  cursor: grab;
  width: 1px;
  background-color: $_component-color;
  position: absolute;
  top: 0px;
  z-index: 1;
  height: 3000px;
}

.timeline__date-bar-label-container {
  cursor: grab;
  white-space: nowrap;
  margin-top: 10px;
  padding: 20px 0 20px 15px;

  p {
    cursor: grab;
    line-height: 16px;
    white-space: nowrap;
  }
}

.timeline__date-bar-drag-handle {
  cursor: grab;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid $_component-color;
  margin-top: -10px;
}

.timeline__date-bar-label-data {
  transition: opacity 400ms ease-in-out;
  transition-delay: 200ms;
  margin-bottom: 0;
  opacity: 100;
  display: inline-block;
}

.timeline__date-bar-label-date {
  margin-bottom: -5px;
}
