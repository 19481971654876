$gridstack-gutter-padding: 1.5rem; // px-4/py-4 utility class

@mixin gridstack-portlet-gutters {
  @extend .pe-4, .pb-4;

  &[gs-y="0"] {
    @extend .pt-4;
  }

  &[gs-x="0"] {
    @extend .ps-4;
  }
}

.grid-stack {
  @extend .pt-4, .ps-4;
}

.grid-stack > .grid-stack-item > .ui-resizable-se {
  position: absolute;
  right: $gridstack-gutter-padding !important;
  bottom: $gridstack-gutter-padding !important;

  @extend .mx-2, .my-2;
  @extend .glyphicon, .glyphicon-resize-full;

  transform: unset !important;
  background-image: none !important;
  font-size: $font-size-base !important;
  top: unset !important;
  height: unset !important;
  width: unset !important;
}
