.portlet {
  .markdown-widget {
    padding: $grid-gutter-width;
    height: 100%;
    overflow-y: scroll;
  }
}

.glyphicon-markdown-widget {
  @extend .glyphicon-pencil;
}
