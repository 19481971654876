#personalized-llm-playlist {
  .small-caps {
    font-variant: small-caps;
    font-weight: $font-weight-lighter;
    margin-bottom: 0px;
  }

  .description-label {
    min-width: 100px;
    white-space: nowrap;
  }

  // label for question in general
  .sub-judgment-form + label {
    width: 100%;
  }

  .answer-wrapper {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 10px;
  }

  .answer-selector {
    position: absolute;
    transform: scale(0);
  }

  // label for each radio button
  .answer-selector + label {
    display: block;
    cursor: pointer;
    box-sizing: border-box;
    min-width: 110px;
    text-align: center;
    padding: 5px 10px;
    border: 1px solid var(--#{$prefix}border-color);
    border-radius: 4px;
    background-color: var(--#{$prefix}body-bg);
  }

  .answer-selector:checked + label {
    color: $white !important;
    background-color: $primary !important;
  }

  .answer-selector:disabled:checked + label {
    background-color: $gray-700 !important;
  }

  @media screen and (max-width: 1200px) {
    .sub-judgment-form {
      padding-left: 15px;
    }
  }

  @media screen and (max-width: 630px) {
    .answer-wrapper {
      flex-direction: column;
    }

    .answer-selector + label {
      display: inline-block;
    }
  }

  @include media-breakpoint-up(lg) {
    .sticky-task {
      position: sticky;
      top: $nav-link-height + $nav-link-padding-y * 2 + map-get($spacers, 4);
    }
  }
}
