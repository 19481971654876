.portlet {
  .ab-experiment-measures-readout-widget {
    .table.dataTable.table-striped > tbody {
      @extend %disposition-bg;

      td {
        background-color: inherit;
      }
    }

    .dtrg-group {
      font-weight: $font-weight-bold;
      text-align: center;
      background-color: $white;

      td {
        border-top: 2px $table-border-color solid;
        border-bottom: 1px $table-border-color solid;
      }

      &:first-child {
        td {
          border-top: 0;
        }
      }
    }

    .search-headers th {
      border-bottom: 2px $table-border-color solid;
    }
  }
}
