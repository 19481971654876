body.permissions.show {
  table {
    span.glyphicon {
      font-size: $icon-size-md;

      &.glyphicon-info-sign {
        cursor: pointer;
      }
    }
  }

  div.popover {
    max-width: 500px;

    h5:first-child {
      margin-top: 0;
      padding-top: 0;
    }
  }
}
