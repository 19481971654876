.taste-profile {
  &__container {
    @extend .col-12, .mb-3;
    max-width: 100vw;

    .tab-pane.active {
      @extend .d-flex;
    }
  }

  &__input-container {
    @extend .col-lg-5, .card, .pb-2, .pt-4, .px-4, .mx-2, .mb-2;

    @include media-breakpoint-up(lg) {
      margin-top: 0.5rem;
    }
  }

  &__table {
    tbody {
      h4, a, p {
        @extend .text-truncate;
      }

      tr.genre-parent-row {
        cursor: pointer;
      }

      tr.genre-children-row {
        display: none;

        :hover {
          background-color: var(--#{$prefix}body-bg);
        }
      }
    }
  }
}
