.portlet {
  .ab-readout-widget {
    .toggle-significance {
      font-weight: $font-weight-normal;
      font-size: $font-size-sm;
      cursor: pointer;

      input[type="checkbox"] {
        margin: 4px 4px 0 0;
      }
    }
  }
}
