#users {
  th.name,
  td.name {
    width: 15%;
  }

  th.email,
  td.email {
    width: 20%;
  }

  th.dsid,
  td.dsid {
    width: 10%;
  }

  th.role,
  td.role {
    width: 10%;
  }

  th.organizations,
  td.organizations {
    width: 15%;
  }

  th.user-active,
  td.user-active {
    width: 10%;
    text-align: center;
  }

  th.actions,
  td.actions {
    width: 5%;
  }
}

.user-avatar {
  @extend .rounded-circle;
  @extend .d-block;
  @extend .d-md-inline;
  @extend .me-2;

  width: 64px;
  height: 64px;
  vertical-align: middle;
}

#user-qualifications {
  .btn-sm {
    min-width: 170px;
  }
}

.team_users.show,
.users.show {
  #eligible-task-widget, #user-audits {
    max-height: 250px;
    overflow-y: auto;
  }
}
