[data-text-redaction] {
  .overlay { display: none; }

  @at-root body:not(.tasks) [data-capabilities~='text-redaction'] & {
    &:not(.show) {
      position: relative;
      cursor: pointer;

      .overlay {
        position: absolute;
        inset: 0;
        z-index: 11;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      text-redaction-content {
        display: inline;
        background-color: $gray-200;
        color: transparent;
      }
    }
  }
}
