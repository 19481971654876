%disposition-color {
  .disposition-success {
    &.significance-positive { color: var(--#{$prefix}success); }
    &.significance-negative { color: var(--#{$prefix}danger); }
  }

  .disposition-failure {
    &.significance-positive { color: var(--#{$prefix}danger); }
    &.significance-negative { color: var(--#{$prefix}success); }
  }
}

%disposition-bg {
  .disposition-success {
    &.significance-positive { background-color: var(--#{$prefix}success-bg-subtle); }
    &.significance-negative { background-color: var(--#{$prefix}danger-bg-subtle); }
  }

  .disposition-failure {
    &.significance-positive { background-color: var(--#{$prefix}danger-bg-subtle); }
    &.significance-negative { background-color: var(--#{$prefix}success-bg-subtle); }
  }
}
