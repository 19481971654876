.card {
  .card-header .glyphicon {
    position: static;
  }

  .card-body {
    .card-title {
      margin-top: 0;
      font-weight: $font-weight-medium;
      line-height: 1.2;
    }

    .card-subtitle {
      font-weight: $font-weight-medium;
      line-height: 1.2;
    }
  }
}
