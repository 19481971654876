th.side-preference-metric,
th.weighted-side-preference-metric,
th.task-count.side-preference-metric,
th.task-count.weighted-side-preference-metric {
  text-align: center;
}

th.side-preference-metric.sub-header,
th.weighted-side-preference-metric.sub-header {
  font-weight: $font-weight-normal;
  font-size: $sub-sup-font-size;
}
