$gutter: 40px;
$column-gutter: 5px;

#timeline__entry-modal .modal-dialog {
  transform: translate(0, -50%);
  top: 50%;
  margin: 0 auto;
  width: 600px;
  max-width: 100vw;
}

#timeline__entry-modal .modal-body {
  display: flex;
  justify-content: center;
  padding: $gutter;
}

#timeline__entry-modal .modal-body > .row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  // remove default gutters
  [class^="col-"], [class*=" col-"] {
    padding-left: 0;
    padding-right: 0;
  }
}

.timeline__modal-subtitle {
  font-weight: $font-weight-lighter;
  margin-bottom: 0;
}

.timeline__modal-data {
  margin: 0;
  font-weight: $font-weight-bold;
  display: inline-block;

  &--name {
    width: 100%;
    text-align: center;
    margin-bottom: $gutter - $column-gutter;
  }

  &--status {
    text-transform: capitalize;
  }
}

.timeline__modal-data-container {
  background-image: linear-gradient(to right, $gray-600, color-mix(in srgb, $gray-600, black 10%));
  border-radius: 15px;
  color: color-contrast($gray-600);
  margin: $column-gutter;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: var(--#{$prefix}box-shadow-sm);

  @media screen and (max-width: 768px) {
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  &--running, &--approved {
    background-image: linear-gradient(to right, $success, color-mix(in srgb, $success, black 10%));
    color: color-contrast($success);
  }
}

.timeline__modal-show-link {
  margin-top: $gutter - $column-gutter;
  display: inline-block;
  text-align: center;
}

.timeline__modal-data--storefront-country-codes {
  max-height: 120px;
  overflow-y: scroll;
}
