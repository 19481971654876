@import "brix/widgets/widgets";

.portlet.portlet-title-widget .widget {
  box-shadow: none;
}

.dashboard.configurable {
  .portlet.portlet-title-widget .widget {
    box-shadow: $widget-box-shadow
  }
}

.portlet {
  .title-widget {
    height: 100%;
  }
}

.glyphicon-title-widget {
  @extend .glyphicon-font;
}
