@import 'colors';

.dashboard-template {
  @extend %disposition-color;

  background: $dashboard-template-bg-color;
  margin-top: -21px;

  &__light-text {
    color: $dashboard-template-font-light-gray;
  }

  a {
    &:hover {
      text-decoration: none;

      .text {
        text-decoration: underline;
      }
    }

    .dashboard-template__icon img {
      filter: invert(44%) sepia(57%) saturate(6868%) hue-rotate(199deg) brightness(100%) contrast(109%);
    }
  }

  &__icon {
    display: inline-block;
    max-width: 20px;

    &-status {
      max-width: 40px;

      img {
        width: 20px !important;
      }
    }

    img {
      width: 15px;
      object-fit: contain;
      padding-bottom: 4px;
    }

    img.big {
      width: 20px;
    }

    img.gray {
      // Setup target color for SVG's as #8B8B8B
      filter: invert(60%) sepia(2%) saturate(2%) hue-rotate(336deg) brightness(91%) contrast(88%);
    }
  }

  &__info-block {
    @extend .p-3;

    background-color: var(--#{$prefix}body-bg);

    border: solid 1px $dashboard-template-color-gray;
    border-radius: 4px;
    min-height: 120px;

    div {
      background: inherit;
    }

    &-title {
      font-size: $h4-font-size;
      font-weight: $font-weight-bold;
    }

    &-filters {
      font-size: $h4-font-size;
    }

    &-filters-details {
      font-size: $h4-font-size;
      cursor: pointer;
    }
  }

  &__table {
    &-control {
      color: $dashboard-template-font-gray;
      background-color: $dashboard-template-color-dark-gray;
    }

    &-treatment {
      color: $dashboard-template-font-gray;
    }

    &-title {
      @extend .m-3;
      border-bottom: solid $dashboard-template-table-gray 1px;
      border-color: var(--#{$prefix}border-color);
    }

    &-row {
      @extend .mx-3;
      border-bottom: solid $dashboard-template-table-gray 1px;
      border-color: var(--#{$prefix}border-color);
    }
  }

  .significance-positive,
  .significance-negative {
    font-weight: $font-weight-bold;
  }

  .result-component {
    &__result {
      margin-bottom: 0;
    }

    &__result-percentage-main {
      font-size: $h3-font-size;
    }

    &__result-percentage-secondary {
      font-size: $font-size-base;
    }

    &__relative-delta {
      font-size: $font-size-base;
    }
  }

  &__status {
    color: $dashboard-template-font-light-gray;

    .dashboard-template__icon-status img {
      filter: invert(51%) sepia(0%) saturate(1%) hue-rotate(201deg) brightness(92%) contrast(93%);
    }
  }

  &__launched,
  &__finished {
    color: var(--#{$prefix}success);

    .dashboard-template__icon-status img {
      filter: invert(16%) sepia(91%) saturate(7495%) hue-rotate(151deg) brightness(98%) contrast(101%);
    }
  }

  &__rejected {
    color: var(--#{$prefix}body-color);

    .dashboard-template__icon-status img {
      filter: invert(0%) sepia(4%) saturate(7%) hue-rotate(297deg) brightness(104%) contrast(100%);
    }
  }

  &__cancelled {
    color: var(--#{$prefix}danger);

    .dashboard-template__icon-status img {
      filter: invert(28%) sepia(86%) saturate(1725%) hue-rotate(340deg) brightness(104%) contrast(103%);
    }
  }
}
