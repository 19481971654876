form#new_portlet trix-editor,
form#edit_portlet trix-editor {
  min-height: 15em;
}

.glyphicon-rich-text-widget {
  @extend .glyphicon-picture;
}

.portlet .rich-text-widget {
  height: 100%;
  overflow-y: scroll;
}
