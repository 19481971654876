.comparison-chart,
.metadata-chart .chart,
.portlet .chart-widget,
.dashboard-template__chart,
#compare-metrics-scatter-plot {
  height: 100%;

  .apexcharts-toolbar {
    top: 5px;
  }

  .apexcharts-tooltip {
    z-index: $zindex-sticky;
  }

  .apexcharts-tooltip-title {
    font-size: $font-size-sm;
  }

  .apexcharts-tooltip-y-group {
    padding: 1px 0;
  }

  .apexcharts-series-group--null-value {
    opacity: 0.5;
    // TODO: replace with @extend .opacity-50 when upgrading to Bootstrap 5
    // https://getbootstrap.com/docs/5.1/utilities/opacity/
  }

  .apexcharts-tooltip-series-group {
    display: flex;
    font-size: $font-size-sm;
  }

  .apexcharts-legend-series.apexcharts-no-click {
    cursor: pointer;
  }

  .apexcharts-svg {
    .apexcharts-xaxis-annotations {
      text,
      line,
      rect {
        visibility: hidden;
      }
    }

    &:hover {
      .apexcharts-xaxis-annotations {
        text,
        line,
        rect {
          visibility: visible;
        }
      }
    }
  }
}

.edit_portlet {
  .y-range {
    .form-control {
      width: 85px;
    }
  }
}

.swatch {
  display: inline-block;
  min-width: 15px;
  min-height: 15px;
  margin: 1px 1px -3px 0;
}
