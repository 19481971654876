a .option-card {
  &:hover {
    box-shadow: $box-shadow;
  }
}
.option-card {
  display: block;
  .card-body {
    min-height: 300px;
  }
  .card-footer {
    min-height: 80px;
  }
}
