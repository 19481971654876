.guidelines.edit,
.guidelines.new {
  trix-editor {
    min-height: 30em;
    max-height: 50vh;
    overflow: auto;
  }

  [data-guidelines-markdown-field="preview"] {
    max-height: 50vh;
    overflow: auto;
  }
}

.guidelines .trix-content {
  font-size: $h5-font-size;
}
