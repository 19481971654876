.list-group.filter-values {
  margin-bottom: 0px;
}

li.bottom {
  margin-bottom: 15px;
}

#tabular-reports {
  td.name,
  th.name {
    width: 40%;
  }

  td.data-source,
  th.data-source {
    width: 20%;
  }

  td.user,
  th.user {
    width: 20%;
  }

  td.updated_at,
  th.updated_at {
    width: 150px;
  }

  td.actions,
  th.actions {
    width: 50px;
  }

}

#tabular-report-filters-fields .remove-filter-item {
  position: absolute;
  top: 10px;
  right: 8px;
  color: $dark;
}

#tabular-report-filters-fields .filter-values {
  .list-group-item {
     padding-right: 25px;
     text-overflow: ellipsis;
     overflow: hidden;
     white-space: nowrap;
  }
}

ul#tabular-report-dimensions-fields,
ul#tabular-report-measures-fields,
ul#tabular-report-filters-fields,
ul#tabular-report-date-filters-fields {

  padding-left: 0px;

  li:not(.select2-selection__choice) {
    position: relative;
    margin-left: 0;
    margin-right: 0;
  }

  .actions {
    position: absolute;
    right: 0;
    top: 0;

    a.reorder-icon,
    a.remove-icon {
      position: absolute;
      top: 8px;
      color: $dark;
    }

    a.reorder-icon {
      left: 10px;
      cursor: move;
    }

    a.remove-icon {
      left: 30px;
    }
  }
}

ul#tabular-report-date-filters-fields {
  li.date-filter[data-type="Tabular::FixedDateFilter"] .period-selector {
    display: none;
  }

  li.date-filter[data-type="Tabular::PeriodDateFilter"] .date-range-selectors {
    display: none;
  }

  .sample-period {
    display: none;
  }
}

#tabular-report-results {
  .no-results,
  .not-runnable {
    text-align: center;
    margin-top: 50px;
  }

  th.measure,
  td.measure {
    text-align: right;
  }

  td.measure {
    @include tabular-numerals;
  }
}

.tabular-data-source-attributes {
  display: none;
}
