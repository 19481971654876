.portlet, .tooltip-inner {
  .ab-experiment-tabular-readout-widget, .ab-experiment-tabular-readout-widget__tooltip {
    @extend %disposition-color;

    .significance-positive,
    .significance-negative {
      font-weight: $font-weight-bold;
    }
  }
}

.ab-experiment-tabular-readout-widget__tooltip {
  @extend .px-4;

  dl {
    @extend .row;
    margin-bottom: 0;
    max-width: 200px;
  }

  dt {
    @extend .col-5;
    text-align: right;
  }
  dd {
    @extend .col-7;
    text-align: left;
    margin-left: -20px;
    white-space: nowrap;
  }
}
