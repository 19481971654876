span.original-answer {
  color: $call-to-action-color;
}

.task-row {
  position: relative;

  &__info {
    position: absolute;
    left: -20px;
    color: $gray-600;
    text-align: right;
    min-width: 11px;
    z-index: 1;

    a {
      color: $gray-600;
    }
  }

  &--responsive {
    hr {
      @extend .my-3;
    }

    .task-row__info {
      position: static;

      @extend .col-1;
      @extend .py-2;

      @include media-breakpoint-up(sm) {
        max-width: 30px;
        padding-left: 0;
      }
    }
  }
}

.ratable-task {
  @extend .my-2;
  min-height: 48px;
  background-color: var(--#{$prefix}light);
}

.ratable-task-text {
  min-height: 48px;
  @extend .d-flex;
  @extend .align-items-center;
}

a.call-to-action {
  color: $call-to-action-color;
}

.edit_task,
.edit_audit_task {
  .button-container {
    text-align: right;
  }
}

.columns .icon-th { display: none; }

.suspected_fraud { background-color: var(--#{$prefix}warning-bg-subtle); }
.confirmed_fraud { background-color: var(--#{$prefix}danger-bg-subtle); }

#update-judgment-error-message:empty { display: none; }
