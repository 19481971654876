$output-media-image-height: 100px;
$output-media-image-width: 100px;
$output-media__audio-height: 35px;

@mixin with-side-by-side-evaluation {
  @at-root [data-evaluation-type^='side_by_side'] & { @content; }
}

.output-media__image-container {
  @extend .pe-3;
  @extend .d-table-cell;
  @extend .align-top;
}

.output-media__image-container--responsive {
  @extend .d-flex, .justify-content-center;
  @extend .my-2, .mx-2, .py-0, .px-0;
  @extend .col-3;

  img, a {
    max-width: 100%;
  }
}

.output-media__image-container--xl {
  @extend .output-media__image-container--responsive;
  @extend .col-11, .col-xl-5;
}

.output-media__metadata-container {
  @extend .w-100;
  @extend .d-table-cell;
  @extend .align-top;
  @extend .remove-paragraph-margins;

  @include media-breakpoint-down(md) {
    padding-right: 15px;
  }

  audio {
    @extend .w-100;
  }
}

.output-media__metadata-container--responsive {
  @extend .d-flex, .flex-column, .justify-content-start;
  @extend .col;
  @extend .my-2, .mx-2, .py-0, .px-0;

  h4 {
    @extend .mt-0, .mb-2;
  }

  p {
    @extend .mb-0;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 0;
  }

  ul.search-links {
    @extend .list-inline;
    &> li {
      @extend .list-inline-item, .pe-1;

      &:last-child {
        @extend .pe-0;
      }
    }
  }

  @include with-side-by-side-evaluation {
    ul.search-links { display: none; }
  }
}

.output-media__metadata-container--full-span {
  @extend .col-11;
  @extend .offset-1;
  @extend .col-sm-12;
  @extend .offset-sm-0;
  @extend .col-lg-12;
  min-height: unset;
}

.output-media__unavailable-image-container {
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  @extend .py-0, .px-0;

  height: $output-media-image-height;
  width: $output-media-image-width;
  max-width: 100%;
  max-height: 100%;
  border-radius: $border-radius;
  background-color: $gray-200;

  img {
    height: 70%;
    width: 70%;
  }
}

.output-media__audio-container {
  @extend .col-11;
  @extend .mb-3;
  @extend .ps-2;
  @extend .d-flex;

  audio {
    @extend .w-100;
    height: $output-media__audio-height;
  }
}

.output-media__description-container {
  @extend .my-3;
  @extend .py-4, .px-4;

  border: 1px solid $border-color;

  p {
    @extend .break-overflow-text;

    &:last-child {
      @extend .mb-0;
    }
  }
}

.output-media__description-container--with-overflow {
  max-height: 25vh;
  overflow-y: scroll;
}
