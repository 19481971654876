.dashboard-template {

  &__status {
    font-size: $h3-font-size;
  }

  &__launch-summary, &__launch-date {
    font-size: $font-size-base;
  }

}
