div.portlet {
  .tabular-report-ab-widget {
    thead {
      tr:first-child th {
        border-bottom: 1px solid $table-border-color;
      }

      tr th {
        border-top: 0;
      }
    }

    th.measure-header {
      text-align: center;
    }

    th.measure-header,
    th.control,
    td.control {
      border-left: 1px solid $table-border-color;
    }
  }
}
