$option-height: 33px;

.select2-results__option[aria-disabled=true] {
  display: none;
}

.select2-selection__choice .info-secondary {
  color: $text-muted;
}

.select2-results__option {
  &:empty { height: $option-height; }
  &:not(.select2-results__option--highlighted) .info-secondary {
    color: $text-muted;
  }
}

select.is-invalid + .select2-container .select2-selection {
  border-color: $form-feedback-invalid-color;
}

.select2-results__option[role="group"] {
  ul.select2-results__options--nested li {
    padding-left: map-get($spacers, 4);
  }
}
