@import "brix/widgets/widgets";

.portlet {
  .ab-experiment-chart-readout-widget {
    height: 100%;

    form {
      position: absolute;
      top: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

.ab-experiment-chart-readout-widget__tooltip {
  @extend %disposition-color;

  table {
    margin-bottom: 0;
  }

  .footer {
    text-align: left;
    margin-bottom: 3px;
    margin-left: 25px;
  }

  .significance-positive,
  .significance-negative {
    font-weight: $font-weight-bold;
  }
}
