.portlet {
  .ab-experiment-measure-readout-widget,
  .ab-experiment-bayesian-readout-widget {
    .table.dataTable.table-striped > tbody {
      @extend %disposition-bg;

      td {
        background-color: inherit;
      }
    }
  }
}
